
//Static Data :----------------
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { API_URL } from "../config";
import axios from "axios";
import { SlideshowLightbox } from 'lightbox.js-react'
import 'lightbox.js-react/dist/index.css'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Avatar from "react-avatar";
import { ToastContainer, toast } from "react-toastify";
import gallary1 from "../assets/gallary1.webp";
import gallary2 from "../assets/gallary2.webp";
import gallary3 from "../assets/gallary3.webp";
import gallary4 from "../assets/gallary4.webp";
import gallary5 from "../assets/gallary5.webp";
import gallary6 from "../assets/gallary6.webp";
import gallary7 from "../assets/gallary7.webp";
import gallary8 from "../assets/gallary8.webp";
import gallary9 from "../assets/gallary9.webp";
import gallary10 from "../assets/gallary10.webp";
import gallary12 from "../assets/gallary12.webp";
import gallary13 from "../assets/gallaryImg/g11.jpg";
import gallary14 from "../assets/gallaryImg/g1.jpg";
import gallary15 from "../assets/gallaryImg/g2.jpg";
import gallary16 from "../assets/gallaryImg/g3.jpg";
import gallary17 from "../assets/gallaryImg/g4.jpg";
import gallary18 from "../assets/gallaryImg/g5.jpg";
import gallary20 from "../assets/gallaryImg/g7.jpg";
import gallary21 from "../assets/gallaryImg/g8.jpg";
import gallary22 from "../assets/gallaryImg/g9.jpg";
import gallary23 from "../assets/gallaryImg/g10.jpg";
import gallary24 from "../assets/gallaryImg/g11.jpg";
import gallary25 from "../assets/gallaryImg/g12.jpg";



const ProjectDetail = () => {
    const { state } = useLocation();

    return (
        <>
            <div className="">
                <div className="p-20 bg-[#A35733]">
                    <p className="text-center text-[50px] text-white font-semibold">{state?.title}</p>
                    <p className="text-center text-[20px] py-5 text-white tracking-wider">{state?.type}</p>
                </div>
                <div className="lg:container md:mx-auto px-5 py-10 mt-16 ">
                    <div className="grid grid-cols-1 lg:grid-cols-2 lg:px-20 gap-20 m-10">
                        {state.galleyImages.map((item) => {
                            return (
                                <div>
                                    <img src={item} style={{ width: "100%", height: "500px" }} alt="" />
                                </div>
                            )
                        })}
                    </div>
                </div>
                <ToastContainer />
            </div >
        </>
    )
}
export default ProjectDetail;